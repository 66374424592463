import * as React from "react"
import PropTypes from "prop-types"
import "./layout.css"

import { NUMBER_OF_TASKS_COMPLETED } from "../constants"

import logo from "../images/logo-72.png"

const Layout = ({ children }) => {
  return (
    <div className="layout container">
      {/* HEADER */}
      <nav
        className="container narrow bar group space-between align-center"
        style={{ margin: "28px auto", padding: "0 8px" }}
      >
        <a className="group align-center" href="/">
          <img
            src={logo}
            alt="OnLion's logo"
            style={{ width: "20px", height: "20px", marginBottom: "2px" }}
          />
          <strong>OnLion </strong>{" "}
          <span style={{ marginLeft: "4px" }}> Blog</span>
        </a>
        <a
          href="https://www.onlion.io/auth/signup"
          target="_blank"
          className="button-wrapper"
          style={{ maxWidth: "50%" }}
        >
          <button>
            Opret <span className="hidden-mobile">Onlion</span> konto{" "}
            <svg
              className="icon"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ marginLeft: "0px" }}
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
        </a>
      </nav>

      {/* BODY */}
      <main
        style={{ marginTop: "40px", padding: "0 8px" }}
        className="container narrow"
      >
        {children}
      </main>
      <hr style={{ marginTop: "32px", marginBottom: "32px" }} />

      {/* Plug */}
      <section
        className="plug container narrow"
        style={{ paddingLeft: "8px", paddingRight: "8px" }}
      >
        <h2 style={{ marginBottom: "20px" }}>
          Få styr på dit vedligehold med en gratis OnLion-konto
        </h2>
        <p style={{ marginBottom: "16px" }}>
          Vi har hjulpet virksomheder som din med at udføre og dokumentere mere
          end {NUMBER_OF_TASKS_COMPLETED} vedligehold.
        </p>
        <p style={{ marginBottom: "16px" }}>
          Få teknologien til at arbejde for dig og dit team med automatiseret
          styring af vedligehold, og opgaver direkte til den ansvarlige.
        </p>
        <div
          style={{ marginBottom: "24px", marginTop: "20px" }}
          className="group"
        >
          <a
            className="button-wrapper"
            target="_blank"
            href="https://www.onlion.io/auth/signup"
          >
            <button>
              Opret en konto
              <svg
                className="icon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ marginLeft: "0px" }}
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </button>
          </a>
          <a
            href="https://www.onlion.io/"
            target="_blank"
            className="button-wrapper"
          >
            <button className="secondary" style={{ marginLeft: "8px" }}>
              Lær mere
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="16" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12.01" y2="8"></line>
              </svg>
            </button>
          </a>
        </div>
      </section>

      <hr style={{ marginTop: "32px", marginBottom: "20px" }} />

      {/* FOOTER */}
      <footer className="container narrow footer" style={{ padding: "0 8px" }}>
        <div className="group row-mobile-col">
          <address style={{ flexBasis: "50%", flexGrow: 2 }}>
            <div className="group align-center" style={{ marginBottom: "8px" }}>
              <img
                style={{ height: "16px", width: "16px", marginBottom: "8px" }}
                src={logo}
                alt="OnLion's logo"
              />
              <p style={{ color: "var(--primary)" }}>
                <b>OnLion I/S</b>
              </p>
            </div>
            <p>CVR 39660806</p>
            <p>Tessebøllevej 54, 4681 Herfølge, Sjælland, Danmark</p>
            <hr />
          </address>

          <div style={{ flexBasis: "50%", flexGrow: 2 }}>
            <p>
              Få bedre overblik og mere tid med automatiseret styring af
              vedligehold.
            </p>
            <p>
              <a href="https://www.onlion.io">Opret en gratis konto</a> for
              automatisk styring af dit vedligehold på op til 10 stykker udstyr,
              eller bare 349kr. per måned for alt dit udstyr.
            </p>
            <hr />
          </div>
        </div>
        <p style={{ textAlign: "center", marginTop: "20px" }}>
          © {new Date().getFullYear()}, viden fra og om
          {` `}
          <a href="https://www.onlion.io">OnLion - vedligehold for alle</a>
        </p>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
