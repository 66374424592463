import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import default_og from "../images/og.png"

interface SeoProps {
  description?: string
  lang?: string
  meta?: Array<{ [key: string]: any }>
  title?: string
  keywords?: string[]
  image?: string
}

const Seo: React.FC<SeoProps> = ({
  description,
  lang,
  meta = [],
  title,
  keywords = ["vedligehold", "onlion", "cmms", "vedligeholdsprogram"],
  image,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang: "da",
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        ...meta,
      ]}
    >
      <link
        rel="preload"
        as="style"
        href="https://onlion-styles.s3.eu-central-1.amazonaws.com/styles.min.css"
      />
      <link
        rel="stylesheet"
        href="https://onlion-styles.s3.eu-central-1.amazonaws.com/styles.min.css"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2e84ed" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff"></meta>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords.join(", ")} />}
      {image && <meta property="og:image" content={image} />}
      {!image && <meta property="og:image" content={default_og} />}
      <meta name="author" content="Nikolaj Gilstrøm & Torben Holmelund"></meta>
    </Helmet>
  )
}

export default Seo
